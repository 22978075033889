import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // put your credentials here
   apiKey: "AIzaSyAIgK9ediEv1LTJhkDXOBrkDU2-6ijlz74",
  authDomain: "pedidosma-cacd0.firebaseapp.com",
  projectId: "pedidosma-cacd0",
  storageBucket: "pedidosma-cacd0.appspot.com",
  messagingSenderId: "168556227346",
  appId: "1:168556227346:web:9479ddc6bdf4a732c5e1cb"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const tiendas = async (mail) => {
  // const userId = auth.currentUser.uid;
  const tiendasCollection = collection(firestore, "tiendas");
  const q = query(tiendasCollection, where("correo", "==",mail));
  const snapshot = await getDocs(q);
  console.log(snapshot);
  return snapshot.docs.map((doc) => doc.data());
};