import axios from "axios";
import {urlAzure} from "../config";

export async function getPedidos(fecha,setValue,setLoading) {
    var data = JSON.stringify(fecha);
    var config = {
        method: 'post',
        url: `${urlAzure}pedidoEcommerce/list`,
        headers: {
          'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          },
        data : data
      };
    const response = await axios(config)
    setValue(response.data)
    setLoading(false);
}
export async function getPedidosExport(fecha,setValue) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}pedidoEcommerce/listExport`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config)
  setValue(response.data)
}
export async function getPedidosProgramados(fecha,setValue,setLoading) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}pedidoEcommerce/listProgramados`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
 
  const response = await axios(config);
  // const res=response.data.filter(e => e.status ==='complete' || e.status === 'payment_confirmed_izipay');
  setValue(response.data)
  setLoading(false);
}
export async function getPedidosProgramadosAll(fecha,setValue,setLoading) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}pedidoEcommerce/listProgramadosAll`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
 
  const response = await axios(config);
  // const res=response.data.filter(e => e.status ==='complete' || e.status === 'payment_confirmed_izipay');
  setValue(response.data)
  setLoading(false);
}
export async function getPedidosProgramadosExport(fecha,setValue) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}pedidoEcommerce/listProgramadosExport`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
 
  const response = await axios(config);
  setValue(response.data)
}
export async function getPedidosProgramadosExportAll(fecha,setValue) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}pedidoEcommerce/listProgramadosExportAll`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
 
  const response = await axios(config);
  setValue(response.data)
}
export async function getPedidosAll(fecha,setValue,setLoading) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}pedidoEcommerce/listAll`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config);
  setValue(response.data);
  setLoading(false);
}
export async function getPedidoId(id,setValue) {
    var data;
    var config = {
        method: 'get',
        url: `${urlAzure}pedidoEcommerce/list/${id}`,
        headers: {
        },
        
        data : data
      };
    const response = await axios(config)
    setValue(response.data)
}
