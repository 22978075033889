import axios from "axios";
import {urlAzure} from "../config";

export async function getDedicatorias(fecha,setValue,setLoading) {
    var data = JSON.stringify(fecha);
    var config = {
        method: 'post',
        url: `${urlAzure}dedicatoria/list`,
        headers: {
            'Content-Type': 'application/json'
          },
        data : data
      };
    const response = await axios(config)
    setValue(response.data)
    setLoading(false);
}

export async function getDedicatoriasTiendas(fecha,setValue,setLoading) {
  var data = JSON.stringify(fecha);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/listAll`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config)
  setValue(response.data)
  setLoading(false);
}
export async function getDedicatoriasIDS(datos,setDedicatoriasIds) {
  const newData = datos.map((item) => {
    if (item.pedido) {
      return { id: item.pedido, tipo: item.tipo };
    }
    return item;
  });
  var data = JSON.stringify(newData);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/pdfs-base64`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config)
  setDedicatoriasIds(response.data);
  return response.data;
}
export async function getDedicatoriaId(datos,setValue) {
  var data = JSON.stringify(datos);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/getIds/`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
  const response = await axios(config)
  setValue(response.data)
}

export async function getImpresionID(id,setValue) {
  var data;
  var config = {
      method: 'get',
      url: `${urlAzure}dedicatoria/pdf-base64/${id}`,
      headers: {},
      data : data
    };
  const response = await axios(config)
  setValue(response.data)
  return response.data;
}


export async function updateDedicatoria(dedicatoria) {
  var data = JSON.stringify(dedicatoria);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/updateComment`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config)
  console.log(response)
  return response;
}

export async function updateStatusDedicatoriaIds(dedicatoria) {
  const datos = dedicatoria.map((item) => {
    if (item.pedido) {
      return { id: item.pedido, tipo: item.tipo };
    }
    return item;
  });

  var data = JSON.stringify(datos);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/getIds`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config);
  const newData = response.data.map((item) => {
    if (item.estatus == "No impreso" || item.estatus == "No Impreso" || item.estatus == "No IMPRESO" || item.estatus == "NO IMPRESO") {
      return { ...item, estatus: "Impreso" };
    }
    return item;
  });
  console.log(newData);
    for (const item of newData) {
      var data = JSON.stringify(item);
      var config = {
          method: 'post',
          url: `${urlAzure}dedicatoria/updateState`,
          headers: {
              'Content-Type': 'application/json'
            },
          data : data
        };
      const response = await axios(config);
      console.log(response.data);
    }
}

export async function updateStatusDedicatoria(dedicatoria) {
  var data = JSON.stringify(dedicatoria);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/updateState`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config)
  console.log(response)
}

export async function insertDedicatoria(datos,setValue) {
  var data = JSON.stringify(datos);
  var config = {
      method: 'post',
      url: `${urlAzure}dedicatoria/create`,
      headers: {
          'Content-Type': 'application/json'
        },
      data : data
    };
  const response = await axios(config)
  setValue(response.data)
}
