import { useAuth } from "../context/AuthContext";
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as PedidosServices from '../services/PedidosServices';
import { MomentLocaleEs } from '../constants/MomentLocaleEs';
import { Link, useNavigate, useParams } from "react-router-dom";
import NavBar from "./NavBar";

export function Detalle() {
    let { id } = useParams();
    const [pedidos, setPedidos] = useState([]);
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        PedidosServices.getPedidoId(id, setPedidos)
    }, [])
    useEffect(() => {
        if (pedidos.productos) {
            setProductos(pedidos.productos)
        }
    }, [pedidos])



    const columns = useMemo(
        () => [
            {
                accessorKey: 'sku', //access nested data with dot notation
                header: 'SKU',
            },
            {
                accessorKey: 'codigoProducto', //access nested data with dot notation
                header: 'Codigo',
            },
            {
                accessorKey: 'nombreProducto', //access nested data with dot notation
                header: 'Nombre Producto',
            },
            {
                accessorKey: 'dedicatoriaProducto', //access nested data with dot notation
                header: 'Dedicatoria',
            },
            {
                accessorKey: 'cantidadProducto', //access nested data with dot notation
                header: 'Cantidad',
            },
            {
                accessorKey: 'precioProducto', //access nested data with dot notation
                header: 'Precio',
            },
            {
                accessorKey: 'subTotalProducto', //access nested data with dot notation
                header: 'Subtotal',
            },

        ],
        [],
    );

    return (
        <div className="w-full max-w-full m-auto text-black">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <NavBar></NavBar>
                <div className="grid grid-cols-1 mt-8">
                    <h2 className="text-3xl font-bold my-4">Order # {id} | {moment(pedidos.fechaDePedido).format("DD/MM/YYYY hh:mm:ss")}</h2>
                    <div className="flex flex-row">
                        <div className="basis-1/2">
                            <div className="bg-gray-200 p-6 mr-8">
                                <h5 className="font-bold"> Pedido # {id} (the order confirmation email was sent)</h5>
                                <div className="basis-1/6">Fecha de Pedido: {moment(pedidos.fechaDePedido).format("DD/MM/YYYY hh:mm:ss")}</div>
                                <div className="basis-1/6">N° Inforest: {pedidos.inforestCode}</div>
                                <div className="basis-1/6">Estado de pago:{pedidos.status === 'pending_payment' ? ' PENDIENTE PAGO' : pedidos.status === 'complete' ? ' COMPLETADO' : pedidos.status === 'processing' ? ' EN PROCESO' : pedidos.status === 'payment_confirmed_izipay' ? 'CONFIRMADO POR IZIPAY' : pedidos.status}</div>
                                <div className="basis-1/6">Estado Inforest: {pedidos.inforestStatus}</div>
                                <div className="basis-1/6">Puesta desde IP: {pedidos.puestaDesdeIP}</div>
                                <div className="basis-1/6">Quote ID: {pedidos.quoteId}</div>
                                <div className="basis-1/6">Fecha de entrega: {pedidos.horarioSeleccionado}</div>
                            </div>
                        </div>
                        <div className="basis-1/2 flex">
                            <div className="bg-gray-200 p-6 mr-8 w-full">
                                <h5 className="font-bold"> Información de la cuenta</h5>
                                <div className="basis-1/6">Nombre del Cliente: {pedidos.nombreDelCliente}</div>
                                <div className="basis-1/6">Correo electrónico: {pedidos.correoElectronico}</div>
                                <div className="basis-1/6">Grupos de Clientes: {pedidos.gruposDeClientes === 'NOT LOGGED IN' ? 'SIN INICIAR SESIÓN' : 'INICIO DE SESIÓN'}</div>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-row">
                        <div className="basis-1/2">
                            <div className="bg-gray-200 p-6 mr-8 mt-4">
                                <h5 className="font-bold"> Dirección de Facturación</h5>
                                <div className="basis-1/6">{pedidos.nombreFacturacion}</div>
                                <div className="basis-1/6">{pedidos.direccionFacturacion}</div>
                                <div className="basis-1/6">{pedidos.referenciaFacturacion}</div>
                                <div className="basis-1/6">{pedidos.ubicacionFacturacion}</div>
                                <div className="basis-1/6">Telefono de facturación: {pedidos.telefonoFacturacion}</div>
                            </div>
                        </div>
                        <div className="basis-1/2">
                            <div className="bg-gray-200 p-6 mr-8 mt-4">
                                <h5 className="font-bold"> Dirección de Envío</h5>
                                <div className="basis-1/6">{pedidos.nombreEnvio}</div>
                                <div className="basis-1/6">{pedidos.direccionEnvio}</div>
                                <div className="basis-1/6">{pedidos.referenciaEnvio}</div>
                                <div className="basis-1/6">{pedidos.ubicacionEnvio}</div>
                                <div className="basis-1/6">Teléfono de envío: {pedidos.telefonoEnvio}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-1/2">
                            <div className="bg-gray-200 p-6 mr-8 mt-4">
                                <h5 className="font-bold"> Información de Pago</h5>
                                <div className="basis-1/6">Tarjeta de Crédito / Débito</div>
                                <div className="basis-1/6">El pedido fue puesto usando PEN</div>
                            </div>
                        </div>
                        <div className="basis-1/2">
                            <div className="bg-gray-200 p-6 mr-8 mt-4">
                                <h5 className="font-bold">Información de Delivery</h5>
                                <div className="basis-1/6">Delivery - Table Rate S/.{pedidos.delivery}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="basis-1/2">
                            <div className="bg-gray-200 p-6 mr-8 mt-4">
                                <h5 className="font-bold">Informacion de comprobantes electrónicos</h5>
                                <div className="basis-1/6">{pedidos.documento}</div>
                                <div className="basis-1/6">{pedidos.razonSocial}</div>
                                <div className="basis-1/6">{pedidos.ruc}</div>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-3xl font-bold my-3">Detalle de Pedidos</h3>
                    <MaterialReactTable
                        columns={columns}
                        data={productos}
                        enableColumnResizing={true}
                        localization={MRT_Localization_ES}

                    />
                    <div className="grid ">
                        <div className="grid-col-12 col-end-6">
                            <div className="bg-gray-200 p-6 mr-8 mt-4">
                                <h5 className="font-bold text-xl">Totales de Pedido</h5>
                                <div className="basis-1/6">Subtotal: {pedidos.subTotal}</div>
                                <div className="basis-1/6">Delivery:{pedidos.delivery}</div>
                                <div className="basis-1/6">
                                    <div className="flex">
                                        <div className="basis-1/3">
                                            <p className="font-bold"> Total General:</p>
                                        </div>
                                        <div className="basis-1/6">
                                            {pedidos.totalGeneral}
                                        </div>
                                    </div>

                                </div>
                                <div className="basis-1/6">
                                    <div className="flex">
                                        <div className="basis-1/3">
                                            <p className="font-bold"> Total Pagado:</p>
                                        </div>
                                        <div className="basis-1/2">
                                        {pedidos.status==='complete' ? pedidos.totalGeneral : '0'}
                                        </div>
                                    </div>
                                </div>
                                <div className="basis-1/6">
                                    <div className="flex">
                                        <div className="basis-1/2">
                                            <p className="font-bold"> Total Reembolsado:</p>
                                        </div>
                                        <div className="basis-1/2">
                                            0
                                        </div>
                                    </div>
                                </div>
                                <div className="basis-1/6">
                                    <div className="flex">
                                        <div className="basis-1/3">
                                            <p className="font-bold"> Deuda Total:</p>
                                        </div>
                                        <div className="basis-1/2">
                                            {pedidos.status!=='complete' ? pedidos.totalGeneral : '0'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
