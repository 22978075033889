import React, { useEffect, useState } from 'react';
import moment from 'moment';
import NavBar from '../NavBar';
import { useAuth } from '../../context/AuthContext';
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
import * as DedicatoriaServices from '../../services/DedicatoriaServices';
import { Link, useNavigate } from "react-router-dom";

import Swal from 'sweetalert2'


const DedicatoriaCreate = () => {
    const navigate = useNavigate();
    const { logout, user,getTiendas } = useAuth();
    const [dedicatoria,setDedicatoria] = useState([]);
    const [nombres,setNombres] = useState([]);
    const [mail,setMail] = useState([]);
    const [tienda,setTienda] = useState([]);
    const [fechahoy, setFechahoy] = useState(moment().locale('es', MomentLocaleEs));
    const [respuesta,setRespuesta]  = useState([]);
    useEffect(() => {
        if(user){
            setMail(user.email);
        }
    }, [user]);
    useEffect(() => {
        if(mail){
          printTiendas();
         }
      }, [mail]);
      const printTiendas = async () => {
       try {
           const tiendas = await getTiendas(mail);
           setTienda(tiendas[0].tienda);
        } catch (error) {
           console.log("Error al obtener las tiendas:", error);
        }
       };
    const handleDedicatoria = (e) => {
        e.preventDefault();
        setDedicatoria(e.target.value);
    }
    const handleNombre = (e) => {
        e.preventDefault();
        setNombres(e.target.value);
    }
    const handleInsertDedicatoria = () =>{
        if(!tienda){
            return;
        }
        if(!dedicatoria){
            return;
        }
        if(!nombres){
            return;
        }
        const insertDedicatoria = {
            id:"0",
            pedido:"",
            horarioSeleccionado:"",
            tienda: tienda, 
            dedicatorias: dedicatoria,
            nombre: nombres,
            estatus: "No Impreso",
            createdDate: moment(fechahoy).format(),
            tipo: "Local"
        };
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'bg-red-500 hover:bg-red-700 px-4 py-3 text-white mr-4',
              cancelButton: 'bg-gray-500 hover:bg-gray-700 px-4 py-3 text-white ml-4'
            },
            buttonsStyling: false
          })
          swalWithBootstrapButtons.fire({
            title: '¿Estas seguro de crear la dedicatoria?',
            text: "Fecha de creación"+moment(fechahoy).format('DD-MM-YYYY'),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
    
            reverseButtons: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                DedicatoriaServices.insertDedicatoria(insertDedicatoria,setRespuesta);
                swalWithBootstrapButtons.fire(  
                    'Se ha creado la dedicatoria'+moment(fechahoy).format('DD-MM-YYYY'),
                    'Correctamente' ,
                    'success'
                   )
            } 
        })
    
      }
    const handleRetroceder = () => {
        navigate('/dedicatoria')
    }
    useEffect(() => {
        if(respuesta){
            console.log("Respuesta del servidor"+respuesta);
        }
    }, [respuesta])
    
 return (
    <div className="w-full max-w-full m-auto text-black">
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
         <NavBar></NavBar> 
        <div className="grid grid-cols-1 mt-8">
            <div className='flex items-center'>
                <h2 className="text-3xl font-bold my-4">Crear dedicatoria</h2>   
                <div><input type="button" name="btnconsutlar" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-3 px-3 rounded ml-2 cursor-pointer" onClick={handleRetroceder} value="Retroceder" /></div>
            </div>
            <div className="flex flex-row">
                <div className="w-full">
                    <div className="bg-gray-200 p-6 mr-8">
                         <div>
                             <label className='font-bold text-lg mt-2'>Nombres</label>
                            <input type="text" className="ml-2 py-1 px-2 md:w-80 ms:w-max" placeholder='Nombre' value={nombres} onChange={(e)=>handleNombre(e)}/>
                         </div>
                         <h5 className='font-bold my-2'>Dedicatorias</h5>
                         <textarea rows="8" className='w-full' value={dedicatoria} maxlength="350" onChange={(e)=>handleDedicatoria(e)}></textarea>
                         <p>Caracteres faltante: {dedicatoria.length} / 340</p>
                         <button 
                         type='button'
                         onClick={handleInsertDedicatoria}
                         className='bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded mr-2 my-4'>Guardar</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
 )
}

export default DedicatoriaCreate
