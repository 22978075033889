import {React } from 'react';

import NavBar from "./NavBar";

export function Home() {


  return (
    <div className="w-full max-w-full m-auto text-black h-screen">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 h-screen">
        <NavBar></NavBar>
        <h1 className="text-4xl font-bold text-center my-4"></h1>
      </div>
    </div>
  );
}
