import React, { useEffect,useState } from 'react'
import { useAuth } from "../context/AuthContext";
import { MomentLocaleEs } from '../constants/MomentLocaleEs';
import { useNavigate } from "react-router-dom";
const NavBar = () => {
    const { logout, user,getTiendas } = useAuth();
    const [mail,setMail] = useState([]);
    const [acceso,setAcceso] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if(mail){
          printTiendas();
         }
      }, [mail]);
      const printTiendas = async () => {
       try {
           const tiendas = await getTiendas(mail);
           setAcceso(tiendas[0].tienda);
        } catch (error) {
           console.log("Error al obtener las tiendas:", error);
        }
       };
       
    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
        } catch (error) {
            console.error(error.message);
        }
    };
    const handleDedicatoria =  () =>{
        navigate("/dedicatoria");
    }
    const handleDedicatoriaTiendas =  () =>{
        navigate("/dedicatoriatienda");
    }
    const handlePedido =  () =>{
        navigate("/pedidoecommerce");
    }

    const handleReemprimir = () =>{
        navigate("/reemprimir");
    }
    const handleProgramadoTiendas = () =>{
        navigate("/pedidosprogramadosgeneral");
    }
    const handlePedidoTiendas =  () =>{
        navigate("/pedidosgeneral");
    }

    const handleReemprimirTiendas = () =>{
        navigate("/reemprimirotrastiendas");
    }
    const handleProgramado = () =>{
        navigate("/pedidosprogramados");
    }
    useEffect(() => {
        if(user){
            setMail(user.email);
        }
    }, [user])
    
 return (
 <>
                 <div className="grid grid-cols-2 gap-4">
                    <div className="col-end-2">
                        { <p className="text-xl mb-4">Bienvenido { mail}</p>  }
                    </div>
                    <div className="col-end-13">
                        { acceso!=='TODO' && <>
                        <button type="button" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handlePedido}>Pedidos Ecommerce</button>
                        <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleProgramado}>Pedidos Programados</button>
                        <button type="button" className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleReemprimir}>Reimpresión Ecommerce</button> 
                        <button type="button" className="bg-violet-500 hover:bg-violet-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleDedicatoria}>Dedicatoria</button> 
                        </>}
                        {
                            acceso==='TODO' && <>
                            <button type="button" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handlePedidoTiendas}>Pedidos Ecommerce</button>
                         <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleProgramadoTiendas}>Pedidos Programados</button> 
                         <button type="button" className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleReemprimirTiendas}>Reimpresión Ecommerce</button> 
                            <button type="button" className="bg-violet-500 hover:bg-violet-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleDedicatoriaTiendas}>Dedicatoria</button> 
                            </> 
                        }
                        <button
                            className="bg-slate-200 hover:bg-slate-300 rounded py-2 px-4 text-black"
                            onClick={handleLogout}
                        >
                        Cerrar Sesión
                        </button>
                    </div>
                </div>
 </>
 )
}

export default NavBar