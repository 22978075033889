import { useAuth } from "../../../context/AuthContext";
import React , { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as PedidosServices from '../../../services/PedidosServices';
import {MomentLocaleEs} from '../../../constants/MomentLocaleEs';
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../NavBar";
import * as XLSX from 'xlsx';

const PedidosTienda = () => {
  const navigate = useNavigate();
  const { logout, user,getTiendas } = useAuth();
  const [pedidos,setPedidos] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(3, 'days'));
  const [fechahoy, setFechahoy] = useState(moment().locale('es', MomentLocaleEs));
  const [mail,setMail] = useState([]);
  const [tienda,setTienda] = useState([]);
  const [loading,setLoading] = useState(true);
  const [excel,setExcel] = useState([]);
  useEffect(() => {
    if(user){
        setMail(user.email);
    }
}, [user])

  useEffect(() => {
    if(tienda){
    const fecha = {
      fromDate: fechaInicio,
      toDate: fechahoy,
      tienda: tienda
    }
    setLoading(true);
    PedidosServices.getPedidos(fecha,setPedidos,setLoading);
    PedidosServices.getPedidosExport(fecha,setExcel);
    
    } 
  }, [tienda])
  useEffect(() => {
    if(mail){
      printTiendas();
     }
  }, [mail]);
  const printTiendas = async () => {
   try {
       const tiendas = await getTiendas(mail);
       setTienda(tiendas[0].tienda);
    } catch (error) {
       console.log("Error al obtener las tiendas:", error);
    }
   };
 


  const handleFecha1 = e => {
    setFechaInicio(moment(e.target.value).locale('es', MomentLocaleEs))   
  }
  const handleFecha2 = e => {
    setFechahoy(moment(e.target.value).locale('es', MomentLocaleEs))   
  }
  const handleTiendasGeneral = () =>{
    navigate("/pedidosgeneral");
  }
  const handleConsultar = () =>{
    const fecha = {
      fromDate: moment(fechaInicio).local('es', MomentLocaleEs),
      toDate: fechahoy,
      tienda: tienda
    }
    setLoading(true);
    PedidosServices.getPedidos(fecha,setPedidos,setLoading);
    PedidosServices.getPedidosExport(fecha,setExcel);
  }
  const fechaCorrecta = (fecha) =>{
    const originalDate = fecha;
      
    // Obtener la fecha y hora por separado
    const date = originalDate.split(' ')[0];
    const time = originalDate.split(' ')[1];

    // Crear un objeto de fecha a partir de la cadena de fecha
    const dateObj = new Date(date);

    // Formatear la fecha y hora en el formato deseado
    const formattedDate = moment(dateObj).format("DD-MM-YYYY");
    const formattedTime = time.replace('-', '-');

    // Concatenar la fecha y hora en la nueva cadena
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: 'pedido', //access nested data with dot notation
        header: 'N° Pedido',
        size: 140,
      },
      {
        accessorKey: 'inforestCode', //access nested data with dot notation
        header: 'N° Inforest',
        size: 150,
      },
      {
        accessorFn: (row) => moment(row.compradoEn).format("DD-MM-YYYY hh:mm.ss"),
        accessorKey: 'compradoEn', //access nested data with dot notation
        header: 'Fecha de pedido',
      },
      {
        accessorFn: (row) => {
          const originalDate = row.horarioSeleccionado;
    
          // Obtener la fecha y hora por separado
          const date = originalDate.split(' ')[0];
          const time = originalDate.split(' ')[1];
    
          // Crear un objeto de fecha a partir de la cadena de fecha
          const dateObj = new Date(date);
    
          // Formatear la fecha y hora en el formato deseado
          const formattedDate = moment(dateObj).format("DD-MM-YYYY");
          const formattedTime = time.replace('-', '-');
    
          // Concatenar la fecha y hora en la nueva cadena
          const formattedDateTime = `${formattedDate} ${formattedTime}`;
    
          return formattedDateTime;
        },
        accessorKey: 'horarioSeleccionado', //access nested data with dot notation
        header: 'Fecha de entrega',
        size: 220,
      },
      {
        accessorFn: (row) => row.status==='processing' ? 'PROCESANDO': row.status==='pending_payment' ? 'PENDIENTE DE PAGO': row.status==='complete'?'COMPLETADO':row.status,
        accessorKey: 'status', //access nested data with dot notation
        header: 'Estado de pago',
      },
      {
        accessorKey: 'inforestStatus', //access nested data with dot notation
        header: 'Estado Inforest',
      },
      {
        accessorKey: 'mEnvio', //access nested data with dot notation
        header: 'M. Envio',
        filterFn: 'equals',
        filterVariant: 'select',
        filterSelectOptions: [{text:'Pickup',value:'Pickup'},{text:'Delivery',value:'Delivery'}],
        size: 140,
        
      },
      {
        accessorKey: 'beetrackStatus',
        header: 'Beetrack Estato',
        size: 140,
      },
      {
        accessorKey: 'beetrackSubstatus',
        header: 'Beetrack Subestado',
        size: 140,
      },
      {
        accessorKey: 'facturarANombre', //access nested data with dot notation
        header: 'Nombre del cliente ',
        size: 200,
      },
      {
        accessorFn: (row) => "S/."+row.compra,
        accessorKey: 'compra', //access nested data with dot notation
        header: 'Total Venta',
        size: 150,
      },
      {
        accessorKey: 'metodoDePago', //access nested data with dot notation
        header: 'Metodo de Pago',
      },
      {
        accessorKey: 'tienda', //access nested data with dot notation
        header: 'Tienda',
      },
      {
        accessorKey: 'comprobanteElectronico', //access nested data with dot notation
        header: 'Comp. Electrónico',
      },
      {
        accessorKey: 'esRegalo', //access nested data with dot notation
        header: 'Es regalo',
      },
      {
        accessorKey: 'tOrigen', //access nested data with dot notation
        header: 'T. Origen',
      },
      {
        accessorKey: 'browser', //access nested data with dot notation
        header: 'Browser',
      }
    ],
    [],
  );

  function exportToExcel() {
    // Crear una hoja de cálculo
    const workbook = XLSX.utils.book_new();
    
    // Crear una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet([]);
  
    // Definir los encabezados de las columnas
    const headers = [
      "N° Pedido","N° Inforest", "Estado de pago", "Fecha de Pedido", "Fecha de entrega", "Nombre del Cliente",
      "Correo Electrónico", "Teléfono de Facturación", "Teléfono de Envío", "Documento",
      "Razón Social", "RUC","Tipo envio", "Delivery", "Subtotal", "Total General", "Nombre del Producto",
      "SKU", "Código del Producto", "Precio del Producto", "Cantidad del Producto",
      "Subtotal del Producto", "Dedicatoria del Producto","Beetrack Estado","Beetrack subestado"
    ];
  
    // Agregar los encabezados de las columnas a la hoja de trabajo
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
  
    // Iterar sobre cada pedido y agregar información de los productos
    excel.forEach(pedido => {
      pedido.productos.forEach(producto => {
        const row = [
          pedido.pedido,pedido.inforestCode, pedido.status==='complete' ? 'COMPLETADO': pedido.status==='payment_confirmed_izipay' ? 'CONFIRMADO POR IZIPAY' : pedido.status==='pending_payment' ? 'PENDIENTE DE PAGO': pedido.status, moment(pedido.fechaDePedido).format("DD-MM-YYYY hh:mm.ss"), fechaCorrecta(pedido.horarioSeleccionado),
          pedido.nombreDelCliente, pedido.correoElectronico, pedido.telefonoFacturacion,
          pedido.telefonoEnvio, pedido.documento, pedido.razonSocial, pedido.ruc,pedido.tipoEnvio,
          pedido.delivery, pedido.subTotal, pedido.totalGeneral, producto.nombreProducto,
          producto.sku, producto.codigoProducto, producto.precioProducto, producto.cantidadProducto,
          producto.subTotalProducto, producto.dedicatoriaProducto,pedido.beetrackStatus,pedido.beetrackSubstatus
        ];
  
        // Agregar la fila a la hoja de trabajo
        XLSX.utils.sheet_add_aoa(worksheet, [row], { origin: -1 });
      });
    });
  
    // Agregar la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedidos Web');
  
    // Exportar el archivo
    XLSX.writeFile(workbook, 'pedidosweb'+moment().locale('es', MomentLocaleEs).format('DD/MM/YYYY')+'.xlsx');
  }
 return (
  <div className="w-full max-w-full m-auto text-black">
  <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
    <NavBar></NavBar>
    <h1 className="text-4xl font-bold text-center my-4">Pedidos Web</h1>
    <div className="flex flex-row items-center">
      <div className="basis-1/3">
      
        <div className="flex flex-row justify-center items-center	">
          <div className="basis-1/2">
            Fecha de pedido inicio
            <input type="date" name="fecha1" value={moment(fechaInicio).format("YYYY-MM-DD")}  onChange={handleFecha1} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
          <div className="basis-1/2 ml-3">
          Fecha de pedido fin
            <input type="date" name="fecha2" value={moment(fechahoy).format("YYYY-MM-DD")}  onChange={handleFecha2} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
          </div>
        </div>
      </div>
      <div className="basis-1/2 ml-3 mt-3">
          <input type="button" name="btnconsutlar" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer" onClick={handleConsultar} value="Consultar" />
          <input type="button" name="btnconsutlar" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2 cursor-pointer" onClick={exportToExcel} value="Exportar" />
          {
            tienda!='TODO' && <>
          <input type="button" name="btnconsutlar" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2 cursor-pointer" onClick={handleTiendasGeneral} value="Pedidos de otras tiendas" />
            </>
          }
      </div>
    </div>
    <div className="grid grid-cols-1 mt-8">
    <MaterialReactTable 
    columns={columns} 
    data={pedidos}
      localization={MRT_Localization_ES}
      enableColumnResizing={true}
      state={{ isLoading: loading }}
      initialState={{ showColumnFilters: true }}
      layoutMode="grid" //instead of the default "semantic" layout mode
muiTableHeadCellProps={{
sx: {
  flex: '0 0 auto',
},
}}
muiTableBodyCellProps={{
sx: {
  flex: '0 0 auto',
},
}}
      muiTableBodyRowProps={({ row }) => ({
        onClick: (event) => {
          navigate("/detalle/"+row._valuesCache.pedido);
        },
        sx: {
          cursor: 'pointer', //you might want to change the cursor too when adding an onClick
        },
      })}
    />
    </div>
  </div>
</div>
 )
}

export default PedidosTienda