import { useAuth } from "../../../context/AuthContext";
import React , { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as PedidosServices from '../../../services/PedidosServices';
import {MomentLocaleEs} from '../../../constants/MomentLocaleEs';
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import NavBar from "../../NavBar";

export function PedidosProgramadosGeneral() {
  const navigate = useNavigate();
  const { logout, user,getTiendas } = useAuth();
  const [pedidos,setPedidos] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs));
  const [fechahoy, setFechahoy] = useState(moment().locale('es', MomentLocaleEs));
  const [mail,setMail] = useState([]);
  const [tienda,setTienda] = useState([]);
  const [loading,setLoading] = useState(true);
  const [excel,setExcel] = useState([]);
  useEffect(() => {
    if(user){
        setMail(user.email);
    }
}, [user])

  useEffect(() => {
    if(tienda){
    const fecha = {
        fromDate: moment(fechaInicio).local('es', MomentLocaleEs).subtract(5, 'hours'),
      toDate: moment(fechahoy).local('es', MomentLocaleEs).subtract(5, 'hours'),
    }
    setLoading(true);
    PedidosServices.getPedidosProgramadosAll(fecha,setPedidos,setLoading);
    PedidosServices.getPedidosProgramadosExportAll(fecha,setExcel);
    } 
  }, [tienda])
  useEffect(() => {
    if(mail){
      printTiendas();
     }
  }, [mail]);
  const printTiendas = async () => {
   try {
       const tiendas = await getTiendas(mail);
       setTienda(tiendas[0].tienda);
    } catch (error) {
       console.log("Error al obtener las tiendas:", error);
    }
   };
 


  const handleFecha1 = e => {
    setFechaInicio(moment(e.target.value).locale('es', MomentLocaleEs))   
  }
  const handleFecha2 = e => {
    setFechahoy(moment(e.target.value).locale('es', MomentLocaleEs))   
  }
  function exportToExcel() {
    // Crear una hoja de cálculo
    const workbook = XLSX.utils.book_new();
    
    // Crear una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet([]);
  
    // Definir los encabezados de las columnas
    const headers = [
        "Tienda",
        "Canal",
        "N° Magento",
        "N° Inforest",
        "Fecha pedido",
        "Fecha entrega",
        "Cliente",
        "Comprobante electronico",
        "Tienda",
        "Producto",
        "Cantidad",
        "Dedicatoria"
    ];
  
    // Agregar los encabezados de las columnas a la hoja de trabajo
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
  
    // Iterar sobre cada pedido y agregar información de los productos
    excel.forEach(pedido => {
      pedido.productos.forEach(producto => {
        const row = [
            pedido.sede,
          pedido.canal,
          pedido.codigoPedidoMagento ? pedido.codigoPedidoMagento : '--------------',
          pedido.codigoPedidoInforest,
          moment(pedido.fecha).format("DD-MM-YYYY"),
          moment(pedido.fechaProgramacion).format("DD-MM-YYYY hh:mm.ss A"),
          pedido.cliente,
          pedido.documento,
          pedido.sede,
          producto.producto,
          producto.cantidad,
          producto.dedicatoria
        ];
  
        // Agregar la fila a la hoja de trabajo
        XLSX.utils.sheet_add_aoa(worksheet, [row], { origin: -1 });
      });
    });
  
    // Agregar la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedidos Programados');
  
    // Exportar el archivo
    XLSX.writeFile(workbook, 'pedidosProgramados'+moment().locale('es', MomentLocaleEs).format('DD/MM/YYYY')+'.xlsx');
  }
  const handleConsultar = () =>{
    const fecha = {
      fromDate: moment(fechaInicio).local('es', MomentLocaleEs).subtract(5, 'hours'),
      toDate: moment(fechahoy).local('es', MomentLocaleEs).subtract(5, 'hours'),
    }
    setLoading(true);
    PedidosServices.getPedidosProgramadosAll(fecha,setPedidos,setLoading);
    PedidosServices.getPedidosProgramadosExportAll(fecha,setExcel);
   
  }
  const fechaCorrecta = (fecha) =>{
    const originalDate = fecha;
      
    // Obtener la fecha y hora por separado
    const date = originalDate.split(' ')[0];
    const time = originalDate.split(' ')[1];

    // Crear un objeto de fecha a partir de la cadena de fecha
    const dateObj = new Date(date);

    // Formatear la fecha y hora en el formato deseado
    const formattedDate = moment(dateObj).format("DD-MM-YYYY");
    const formattedTime = time.replace('-', '-');

    // Concatenar la fecha y hora en la nueva cadena
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  }
  const columns = useMemo(
    () => [
        {
            accessorKey: 'sede', //access nested data with dot notation
            header: 'Tienda',
            size: 140,
          },
      {
        accessorKey: 'canal', //access nested data with dot notation
        header: 'Canal',
        size: 140,
      },
      {
        accessorFn: (row) =>row.codigoPedidoMagento ? row.codigoPedidoMagento : '-----------------',
        accessorKey: 'codigoPedidoMagento', //access nested data with dot notation
        header: 'N° Magento',
        size: 155,
      },
      {
        accessorKey: 'codigoPedidoInforest', //access nested data with dot notation
        header: 'N° Inforest',
        size: 150,
      },
      {
        accessorFn: (row) => moment(row.fecha).format("DD-MM-YYYY"),
        accessorKey: 'fecha', //access nested data with dot notation
        header: 'Fecha de pedido',
      },
      {
        accessorFn: (row) => moment(row.fechaProgramacion).format("DD-MM-YYYY hh:mm.ss A"),
        accessorKey: 'fechaProgramacion', //access nested data with dot notation
        header: 'Fecha Entrega',
      },
      {
        accessorKey: 'cliente', //access nested data with dot notation
        header: 'Cliente',
        size: 270,
      },
      {
        accessorKey: 'documento', //access nested data with dot notation
        header: 'Comp. Electrónico',
        size: 200,
      }
    ],
    [],
  );

  return (
    <div className="w-full max-w-full m-auto text-black">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <NavBar></NavBar>
        <h1 className="text-4xl font-bold text-center my-4">Pedidos programados tiendas</h1>
        <div className="flex flex-row items-center">
          <div className="basis-1/3">
          
            <div className="flex flex-row justify-center items-center">
              <div className="basis-1/2">
                Fecha de entrega inicio
                <input type="date" name="fecha1" value={moment(fechaInicio).format("YYYY-MM-DD")}  onChange={handleFecha1} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
              </div>
              <div className="basis-1/2 ml-3">
                Fecha de entrega fin
                <input type="date" name="fecha2" value={moment(fechahoy).format("YYYY-MM-DD")}  onChange={handleFecha2} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
              </div>
            </div>
          </div>
          <div className="basis-1/2 ml-3 mt-3">
              <input type="button" name="btnconsutlar" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer" onClick={handleConsultar} value="Consultar" />
              <input type="button" name="btnconsutlar" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2 cursor-pointer" onClick={exportToExcel} value="Exportar" />
          </div>
        </div>
        <div className="grid grid-cols-1 mt-8">
        <MaterialReactTable 
        columns={columns} 
        data={pedidos}
          localization={MRT_Localization_ES}
          enableColumnResizing={true}
          state={{ isLoading: loading }}
          layoutMode="grid" //instead of the default "semantic" layout mode
  muiTableHeadCellProps={{
    sx: {
      flex: '0 0 auto',
    },
  }}
  muiTableBodyCellProps={{
    sx: {
      flex: '0 0 auto',
    },
  }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              navigate("/detalle/"+row._valuesCache.codigoPedidoMagento);
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
          })}
        />
        </div>
      </div>
    </div>
  );
}
