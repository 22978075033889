import { useAuth } from "../../context/AuthContext";
import React , { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as ReemprimirServices from '../../services/ReemprimirServices';
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';

import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import NavBar from "../NavBar";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from '@mui/material';
// import SilkaRegular from '../../file/silka-regular-webfont.ttf'


const Reemprimir = () => {
    const navigate = useNavigate();
    const { logout, user,getTiendas } = useAuth();
    const [pedidos,setPedidos] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(3, 'days'));
    const [fechahoy, setFechahoy] = useState(moment().locale('es', MomentLocaleEs));
    const [docpdf,SetDocpdf] = useState([]);
    const [mail,setMail] = useState([]);
    const [tienda,setTienda] = useState([]);
    const [loading,setLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [dedicatoriasIds,setDedicatoriasIds] =useState([]);
    useEffect(() => {
      if(user){
          setMail(user.email);
      }
  }, [user])
    useEffect(() => {
      if(tienda){
      const fecha = {
        fromDate: fechaInicio,
        toDate: fechahoy,
        tienda: tienda
      }
      setLoading(true);
      ReemprimirServices.getReemprimir(fecha,setPedidos,setLoading);
      }
    }, [tienda])
    useEffect(() => {
      if(mail){
        printTiendas();
       }
    }, [mail]);
    const printTiendas = async () => {
     try {
         const tiendas = await getTiendas(mail);
         setTienda(tiendas[0].tienda);
      } catch (error) {
         console.log("Error al obtener las tiendas:", error);
      }
     };
    const verDedicatoria = (e,row) =>{
      e.preventDefault();
      navigate("/dedicatoria/"+row._valuesCache.pedido)
    }

    useEffect(() => {
       if(!docpdf){
         console.log(docpdf);
      }
  }, [docpdf])
    
    const imprimirBoleta = async (e,row,tipo) => {
      e.preventDefault();
      
      const imprimir = {
        inforestCode:row.original.inforestCode,
        tienda: tienda,
        tipo: tipo
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'bg-red-500 hover:bg-red-700 px-4 py-3 text-white mr-4',
          cancelButton: 'bg-gray-500 hover:bg-gray-700 px-4 py-3 text-white ml-4'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: tipo==2?'¿Estas seguro de imprimir Boleta/Factura?':tipo==1?'¿Estas seguro de imprimir la Comanda?':'¿Estas seguro de imprimir la precuenta?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',

        reverseButtons: false
      }).then(async (result) => {
        if (result.isConfirmed) {
         const respuesta=ReemprimirServices.PostobtenerDocumento(imprimir);
        
         
            swalWithBootstrapButtons.fire(  
             ''+tipo==2?'Has impreso la Boleta/Factura':tipo==1?'Has impreso la Comanda':'Has impreso la Precuenta',
             'Correctamente' ,
             'success'
            )
          
        } 
      })

    }


    const handleFecha1 = e => {
      setFechaInicio(moment(e.target.value).locale('es', MomentLocaleEs))   
    }
    const handleFecha2 = e => {
      setFechahoy(moment(e.target.value).locale('es', MomentLocaleEs))   
    }
    const handleConsultar = () =>{
      const fecha = {
        fromDate: moment(fechaInicio).local('es', MomentLocaleEs),
        toDate: fechahoy,
        tienda: tienda
      }
      setLoading(true);
      ReemprimirServices.getReemprimir(fecha,setPedidos,setLoading);
    }
    const columns = useMemo(
      () => [
          {
            accessorKey: 'pedido',
            header: 'N° Magento',
            size: 170,
          },
          {
            accessorKey: 'inforestCode',
            header: 'N° Inforest',
            size: 150,
          },
          {
            accessorFn: (row) => moment(row.compradoEn).format("DD-MM-YYYY"),
            accessorKey: 'compradoEn',
            header: 'Fecha Pedido',
            size: 170,
          },
          {
            accessorFn: (row) => {
              const originalDate = row.horarioSeleccionado;
        
              // Obtener la fecha y hora por separado
              const date = originalDate.split(' ')[0];
              const time = originalDate.split(' ')[1];
        
              // Crear un objeto de fecha a partir de la cadena de fecha
              const dateObj = new Date(date);
        
              // Formatear la fecha y hora en el formato deseado
              const formattedDate = moment(dateObj).format("DD-MM-YYYY");
              const formattedTime = time.replace('-', '-');
        
              // Concatenar la fecha y hora en la nueva cadena
              const formattedDateTime = `${formattedDate} ${formattedTime}`;
        
              return formattedDateTime;
            },
            accessorKey: 'horarioSeleccionado', 
            header: 'Fecha de entrega',
            size: 190,
          },
          {
            accessorKey: 'estadoBoleta',
            header: 'Boleta/Factura',
            size: 140,
          },
          {
            accessorKey: 'estadoComanda',
            header: 'Comanda',
            size: 140,
          },
          {
            accessorKey: 'estadoPrecuenta',
            header: 'Precuenta',
            size: 140,
          },
          {
            accessorKey: 'mEnvio',
            header: 'M. Envio',
            size: 140,
          },
          {
            accessorKey: 'compra',
            header: 'Total Venta',
            size: 140,
          },
          {
            accessorKey: 'facturarANombre',
            header: 'Cliente',
          },
          {
            accessorKey: 'tienda',
            header: 'Tienda',
          },
      ],
      [],
    );


 return (
    <div className="w-full max-w-full m-auto text-black">
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
       <NavBar></NavBar> 
      <h1 className="text-4xl font-bold text-center my-4">Reemprimir</h1>
      <div className="flex flex-row items-center">
        <div className="basis-1/3">
        
          <div className="flex flex-row justify-center items-center">
            <div className="basis-1/2">
              Fecha de entrega inicio
              <input type="date" name="fecha1" value={moment(fechaInicio).format("YYYY-MM-DD")}  onChange={handleFecha1} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div className="basis-1/2 ml-3">
            Fecha de entrega fin
              <input type="date" name="fecha2" value={moment(fechahoy).format("YYYY-MM-DD")}  onChange={handleFecha2} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
          </div>
        </div>
        <div className="basis-1/2 ml-3 mt-3">
            <input type="button" name="btnconsutlar" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleConsultar} value="Consultar" />
            {/* <input type="button" name="btnExportar" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2" onClick={handleFormSubmit} value="Generar PDF" /> */}
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8">
       <MaterialReactTable 
      columns={columns} 
      data={pedidos}
      enableRowActions
      enableColumnResizing={true}
      state={{ isLoading: loading }}
 
      layoutMode="grid" //instead of the default "semantic" layout mode
  muiTableHeadCellProps={{
    sx: {
      flex: '0 0 auto',
    },
  }}
  muiTableBodyCellProps={{
    sx: {
      flex: '0 0 auto',
    },
  }}
  displayColumnDefOptions={{
    'mrt-row-actions': {
      header: 'Imprimir', //change header text
      size: 340, //make actions column wider
      muiTableHeadCellProps: {
        align: 'center', //change head cell props
      },
    },
  }}
      renderRowActions={({ row, table }) => (
        <div className='flex flex-row'>
          <button className='bg-blue-500 text-white py-2 px-4 cursor-pointer' onClick={(e)=>imprimirBoleta(e,row,2)}>Boleta/Factura</button>
          <button className='bg-red-500 text-white py-2 px-4 cursor-pointer mr-2 ml-2' onClick={(e)=>imprimirBoleta(e,row,1)}>Comanda</button>
          <button className='bg-green-500 text-white py-2 px-4 cursor-pointer' onClick={(e)=>imprimirBoleta(e,row,4)}>Precuenta</button>
        </div>
        )}
        localization={MRT_Localization_ES}
        
      />
      </div>
    </div>
  </div>
 )
}

export default Reemprimir