import { useAuth } from "../../../context/AuthContext";
import React , { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as DedicatoriaServices from '../../../services/DedicatoriaServices';
import {MomentLocaleEs} from '../../../constants/MomentLocaleEs';

import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import NavBar from "../../NavBar";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from '@mui/material';
// import SilkaRegular from '../../file/silka-regular-webfont.ttf'






const DedicatoriaTienda = () => {
    const navigate = useNavigate();
    const { logout, user,getTiendas } = useAuth();
    const [pedidos,setPedidos] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(1, 'days'));
    const [fechahoy, setFechahoy] = useState(moment().locale('es', MomentLocaleEs));
    const [docpdf,SetDocpdf] = useState([]);
    const [mail,setMail] = useState([]);
    const [tienda,setTienda] = useState([]);
    const [loading,setLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [dedicatoriasIds,setDedicatoriasIds] =useState([]);
    useEffect(() => {
      if(user){
          setMail(user.email);
      }
  }, [user])
    useEffect(() => {
      if(tienda){
      const fecha = {
        fromDate: fechaInicio,
        toDate: fechahoy
      }
      setLoading(true);
      DedicatoriaServices.getDedicatoriasTiendas(fecha,setPedidos,setLoading);
      }
    }, [tienda])
    useEffect(() => {
      if(mail){
        printTiendas();
       }
    }, [mail]);
    const printTiendas = async () => {
     try {
         const tiendas = await getTiendas(mail);
         setTienda(tiendas[0].tienda);
      } catch (error) {
         console.log("Error al obtener las tiendas:", error);
      }
     };
     const verDedicatoria = (e,row) =>{
      e.preventDefault();
      let url = "/dedicatoria/";

      if (row._valuesCache.pedido) {
        url += row._valuesCache.pedido + "?tipo="+row._valuesCache.tipo;
      } else {
        url += row._valuesCache.id + "?tipo="+row._valuesCache.tipo;
      }
    
      navigate(url);
    }

    useEffect(() => {
       if(!docpdf){
         console.log(docpdf);
      }
  }, [docpdf])
    
  const imprimirDedicatoria = async (e,row) => {
    e.preventDefault();
    

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'bg-red-500 hover:bg-red-700 px-4 py-3 text-white mr-4',
        cancelButton: 'bg-gray-500 hover:bg-gray-700 px-4 py-3 text-white ml-4'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: '¿Estas seguro de imprimir la dedicatoria?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',

      reverseButtons: false
    }).then(async (result) => {
      if (result.isConfirmed) {
      const respuesta=DedicatoriaServices.updateStatusDedicatoriaIds(selectedIds);
        const respuesta2= dedicatoriasIds;
        const bytes = atob(respuesta2);
        const byteNumbers = new Array(bytes.length);
        for (let i = 0; i < bytes.length; i++) {
          byteNumbers[i] = bytes.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "dedicatoria.pdf";
        link.click();
          swalWithBootstrapButtons.fire(  
           'Has impreso la dedicatoria',
           'Correctamente' ,
           'success'
          )
        
      } 
    })

  }

    const handleFecha1 = e => {
      setFechaInicio(moment(e.target.value).locale('es', MomentLocaleEs))   
    }
    const handleFecha2 = e => {
      setFechahoy(moment(e.target.value).locale('es', MomentLocaleEs))   
    }
    const handleConsultar = () =>{
      const fecha = {
        fromDate: moment(fechaInicio).local('es', MomentLocaleEs),
        toDate: fechahoy,
        tienda: tienda
      }
      setLoading(true);
      DedicatoriaServices.getDedicatoriasTiendas(fecha,setPedidos,setLoading);
    }
    const columns = useMemo(
      () => [
        {
          accessorKey: 'tienda', 
          header: 'Tienda',
        },
        {
          accessorKey: 'id', 
          header: 'Id',
        },
        {
          accessorKey: 'tipo', 
          header: 'Tipo',
        },
        {
            accessorKey: 'pedido',
            header: 'Pedido',
          },
          {
            accessorFn: (row) => {
                    const originalDate = row.horarioSeleccionado;
              if(!originalDate){
                return 'No disponible'
              }
              // Obtener la fecha y hora por separado
              const date = originalDate.split(' ')[0];
              const time = originalDate.split(' ')[1];
        
              // Crear un objeto de fecha a partir de la cadena de fecha
              // const dateObj = new Date(date);
        
              // Formatear la fecha y hora en el formato deseado
              const formattedDate = moment(date).format("DD-MM-YYYY");
              const formattedTime = time.replace('-', '-');
        
              // Concatenar la fecha y hora en la nueva cadena
              const formattedDateTime = `${formattedDate} ${formattedTime}`;
        
              return formattedDateTime;
            },
            accessorKey: 'horarioSeleccionado', 
            header: 'Fecha de entrega',
          },
          {
            accessorKey: 'nombre', 
            header: 'Nombre del Cliente',
          },
          {
            accessorKey: 'dedicatorias', 
            header: 'Dedicatoria',
          },
          {
            accessorKey: 'estatus', 
            header: 'Estatus',
          },
          {
            accessorKey: 'createdDate', 
            header: 'Fecha de Creación',
          },
      ],
      [],
    );
    const handleCheckboxChange = (pedido, tipo) => {
      const updatedIds = [...selectedIds];
    
      if (tipo === "Local") {
        const index = updatedIds.findIndex((item) => item.id === pedido && item.tipo === tipo);
    
        if (index !== -1) {
          updatedIds.splice(index, 1);
        } else {
          updatedIds.push({ id: pedido, tipo: tipo });
        }
      } else if (tipo === "Ecommerce" || tipo === "AIMO") {
        const indexPedido = updatedIds.findIndex((item) => item.pedido === pedido && item.tipo === tipo);
        const indexId = updatedIds.findIndex((item) => item.id === pedido && item.tipo === tipo);
    
        if (indexPedido !== -1) {
          updatedIds.splice(indexPedido, 1);
        } else if (indexId !== -1) {
          updatedIds.splice(indexId, 1, { pedido: pedido, tipo: tipo });
        } else {
          updatedIds.push({ pedido: pedido, tipo: tipo });
        }
      }
    
      setSelectedIds(updatedIds);
    };
    
    useEffect(() => {
      if (selectedIds.length > 0) {
        const mappedIds = selectedIds.map(({ id, pedido, tipo }) => (tipo === "Ecommerce" || tipo === "AIMO" ? { pedido, tipo } : { id, tipo }));
        DedicatoriaServices.getDedicatoriasIDS(mappedIds, setDedicatoriasIds);
      }
    }, [selectedIds]);

 return (
    <div className="w-full max-w-full m-auto text-black">
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
       <NavBar></NavBar> 
      <h1 className="text-4xl font-bold text-center my-4">Dedicatorias Tiendas</h1>
      <div className="flex flex-row items-center">
        <div className="basis-1/3">
        
          <div className="flex flex-row justify-center items-center">
            <div className="basis-1/2">
              Fecha de entrega inicio
              <input type="date" name="fecha1" value={moment(fechaInicio).format("YYYY-MM-DD")}  onChange={handleFecha1} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
            <div className="basis-1/2 ml-3">
            Fecha de entrega fin
              <input type="date" name="fecha2" value={moment(fechahoy).format("YYYY-MM-DD")}  onChange={handleFecha2} className="shadow appearance-none border border-black-600 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"/>
            </div>
          </div>
        </div>
        <div className="basis-1/2 ml-3 mt-3">
            <input type="button" name="btnconsutlar" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleConsultar} value="Consultar" />
            <input type="button" name="btnExportar" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2 cursor-pointer" onClick={imprimirDedicatoria} value="Generar PDF" />
        
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8">
       <MaterialReactTable 
      columns={columns} 
      data={pedidos}
      enableRowActions
      enableColumnResizing={false}
      columnResizeMode="onEnd" 
      state={{ isLoading: loading }}
      renderRowActions={({ row, table }) => (
        <div className='flex flex-row'>
          <button className='bg-blue-500 text-white py-2 px-4 cursor-pointer' onClick={(e)=>verDedicatoria(e,row)}>Ver</button>
          <input
  type="checkbox"
  id={row.original.pedido}
  onChange={() => handleCheckboxChange(row.original.pedido || row.original.id, row.original.tipo)}
  checked={selectedIds.some((item) => (item.pedido === row.original.pedido || item.id === row.original.id) && item.tipo === row.original.tipo)}
  className="w-20"
/>
        </div>
        )}
        localization={MRT_Localization_ES}

        
      />
      </div>
    </div>
  </div>
 )
}

export default DedicatoriaTienda
