import axios from "axios";
import {urlAzure} from "../config";

export async function getReemprimir(fecha,setValue,setLoading) {
    var data = JSON.stringify(fecha);
    var config = {
        method: 'post',
        url: `${urlAzure}pedidoEcommerce/listReimpresion`,
        headers: {
            'Content-Type': 'application/json'
          },
        data : data
      };
    const response = await axios(config)

    setValue(response.data);
    setLoading(false);
}


export async function getReemprimirAll(fecha,setValue,setLoading) {
    var data = JSON.stringify(fecha);
    var config = {
        method: 'post',
        url: `${urlAzure}pedidoEcommerce/listReimpresionAll`,
        headers: {
            'Content-Type': 'application/json'
          },
        data : data
      };
    const response = await axios(config)

    setValue(response.data);
    setLoading(false);
}
  
export async function PostobtenerDocumento(imprimir) {
    var data = JSON.stringify(imprimir);
    var config = {
        method: 'post',
        url: `${urlAzure}pedidoEcommerce/updateReimpresion`,
        headers: {
            'Content-Type': 'application/json'
          },
        data : data
      };
    const response = await axios(config)
      return response.data;
}

