import React from "react";
import { Route, Routes,useLocation  } from "react-router-dom";
import { Login } from "./components/Login";
import { Register } from "./components/Register";
import { Home } from "./components/Home";
import { ProtectedRoute } from "./components/ProtectedRoute";

import { AuthProvider } from "./context/AuthContext";
import { Detalle } from "./components/Detalle";
import Dedicatoria from "./components/Dedicatoria/Dedicatoria";
import { DedicatoriaDetalle } from "./components/Dedicatoria/DedicatoriaDetalle";
import { PedidosGeneral } from "./components/PedidosGeneral/PedidosGeneral";
import { PedidosProgramados } from "./components/PedidosProgramados/PedidosProgramados";
import Reemprimir from "./components/Reemprimir/Reemprimir";
import ReemprimirTiendas from "./components/Reemprimir/ReemprimirTiendas";
import PedidosTienda from "./components/Page/PedidosTienda/PedidosTienda";
import {PedidosProgramadosGeneral} from "./components/Page/PedidosProgramadosGeneral/PedidosProgramadosGeneral";
import DedicatoriaTienda from "./components/Page/DedicatoriaTiendas/DedicatoriaTienda";
import DedicatoriaCreate from "./components/Dedicatoria/DedicatoriaCreate";
function App() {
  const location = useLocation();
  return (
    <div className="bg-slate-300 h-screen flex text-white">
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
       
          <Route path="/register" element={<Register />} />
          <Route path="/detalle" element={<Detalle />} />
          <Route path="/detalle/:id" element={<Detalle />} />
          <Route path="/dedicatoria" element={<Dedicatoria />} />
          <Route path="/dedicatoria/:id" element={<DedicatoriaDetalle />} />
          <Route path="/pedidosgeneral" element={<PedidosGeneral />} />
          <Route path="/pedidosprogramados" element={<PedidosProgramados />} />
          <Route path="/reemprimir" element={<Reemprimir />} />
          <Route path="/reemprimirotrastiendas" element={<ReemprimirTiendas />} />
          <Route path="/pedidoecommerce" element={<PedidosTienda />} />
          <Route path="/pedidosprogramadosgeneral" element={<PedidosProgramadosGeneral />} />
          <Route path="/dedicatoriatienda" element={<DedicatoriaTienda />} />
          <Route path="/dedicatoriacreate" element={<DedicatoriaCreate />} />

          
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
