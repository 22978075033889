import React, { useEffect, useMemo, useState } from 'react';

import * as DedicatoriaServices from '../../services/DedicatoriaServices';
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import NavBar from "../NavBar";

export function DedicatoriaDetalle() {
    let { id } = useParams();
    const tipo = new URLSearchParams(window.location.search).get('tipo');

    const [pedidos, setPedidos] = useState([]);
    const [productos, setProductos] = useState([]);
    const [dedicatoria,setDedicatoria] = useState([]);
    useEffect(() => {
        const dedicatoria = [{
                id:id,
                tipo:tipo
        }];
        DedicatoriaServices.getDedicatoriaId(dedicatoria, setPedidos)
    }, [])
    useEffect(() => {
        if (pedidos[0]) {
           setDedicatoria(pedidos[0].dedicatorias)
        }
    }, [pedidos])


    const handleDedicatoria = e => {
        setDedicatoria(e.target.value)
    }
    const handleUpdate = () =>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'bg-red-500 hover:bg-red-700 px-4 py-3 text-white mr-4',
              cancelButton: 'bg-gray-500 hover:bg-gray-700 px-4 py-3 text-white ml-4'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: '¿Estas seguro de actualizar la dedicatoria?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar',
    
            reverseButtons: false
          }).then((result) => {
            if (result.isConfirmed) {
                const detalle = {
                    id:pedidos[0].id,
                    nombre:pedidos[0].nombre,
                    pedido:pedidos[0].pedido,
                    tienda:pedidos[0].tienda,
                    tipo:pedidos[0].tipo,
                    estatus:pedidos[0].estatus,
                    createdDate:pedidos[0].createdDate,
                    horarioSeleccionado:pedidos[0].horarioSeleccionado,
                    dedicatorias:dedicatoria
                }
                const respuesta=DedicatoriaServices.updateDedicatoria(detalle);
                console.log(respuesta);
              swalWithBootstrapButtons.fire(
                'Has actualizado correctamente la dedicatoria',
                'Con el numero de pedido : '+pedidos.pedido,
                'success'
              )
            } 
          })
    }

    return (
        <div className="w-full max-w-full m-auto text-black">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                 <NavBar></NavBar> 
                <div className="grid grid-cols-1 mt-8">
                {pedidos && pedidos.length>0 && <h2 className="text-3xl font-bold my-4">Editar dedicatoria {pedidos[0].tipo==='de ecommerce' ? 'Ecommerce':'de tienda'}</h2>}
                    <div className="flex flex-row">
                        <div className="w-full">
                            {pedidos && pedidos.length>0 &&
                            <div className="bg-gray-200 p-6 mr-8">
                                <h5 className="font-bold text-xl">  Pedido # {pedidos[0].pedido ? pedidos[0].pedido : pedidos[0].id}</h5>
                                 <h5 className='font-bold my-2'>Dedicatorias</h5>
                                 <textarea rows="8" className='w-full' value={dedicatoria} onChange={handleDedicatoria}></textarea>
                                 <p className="text-black font-bold">  Estatus:  {pedidos[0].estatus}</p>
                                 <p className="text-black font-bold">  Fecha de creación:  {pedidos[0].createdDate}</p>
                                 <button 
                                 type='button'
                                 onClick={handleUpdate}
                                 className='bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded mr-2 my-4'>Guardar</button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
